<template>
  <div id="forum-logs-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-chat-text"></i>
          <span style="margin-left: 5px"> Forum Logs </span>
        </b-card-title>
        <!-- filter component -->
        <div class="d-flex">
          <!-- date filter -->
          <date-picker-component />
          <!-- keyword filter -->
          <b-input-group class="input-group-merge ml-1" style="width: 20vw">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Masukkan kata kunci..."
              v-model="name"
              style="height: 3rem"
            />
          </b-input-group>
        </div>
      </b-card-header>
      <!-- loader component -->
      <loader-component v-if="is_loading" />
      <!-- table-component -->
      <table-component
        v-else
        :data="table_data"
        :is_row_clicked="true"
        :viewDetail="viewDetail"
        class="mt-1"
      />
    </b-card>
    <!-- detail forum log modal component -->
    <detail-forum-log-component ref="detail_forum" />
  </div>
</template>
<script>
import axios from "axios";
import $ from "jquery";
import useJwt from "@/auth/jwt/useJwt";
import dataTable from "@/views/components/dataTable.vue";
import loader from "@/views/components/loader.vue";
import datePicker from "@/views/components/datePicker.vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import detailForumLogs from "../components/forum/detailForumLogs.vue";

export default {
  name: "ForumLogs",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    "table-component": dataTable,
    "loader-component": loader,
    "date-picker-component": datePicker,
    "detail-forum-log-component": detailForumLogs,
  },
  data() {
    return {
      is_loading: true,
      from_date: this.$route.query.from_date,
      to_date: this.$route.query.to_date,
      tier: null,
      page: this.$route.query.page ? this.$route.query.page : 1,
      items: this.$route.query.items ? this.$route.query.items : 10,
      name: null,
      table_data: {
        page_info: {
          limit: 10,
          page: 1,
          total: 0,
          _id: null,
        },
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "table-header text-center",
            tdClass: "text-center",
          },
          {
            key: "scraper_name",
            label: "nama scraper",
            thClass: "table-header text-left",
            tdClass: "text-left",
          },
          {
            key: "error_message",
            label: "pesan error",
            thClass: "table-header text-left",
            tdClass: "text-left",
          },
          {
            key: "start",
            label: "tanggal berjalan",
            sortable: true,
            thClass: "table-header text-center",
            tdClass: "text-center",
          },
          {
            key: "end",
            label: "tanggal terhenti",
            sortable: true,
            thClass: "table-header text-center",
            tdClass: "text-center",
          },
          {
            key: "status",
            label: "status",
            thClass: "table-header text-center",
            tdClass: "text-center",
          },
          {
            key: "count",
            label: "jumlah berita",
            thClass: "table-header text-center",
            tdClass: "text-center",
          },
          {
            key: "duration",
            label: "durasi",
            thClass: "table-header text-center",
            tdClass: "text-center",
          },
        ],
        items: null,
      },
      cancelToken: null,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    "$route.query"() {
      this.page = this.$route.query.page ? this.$route.query.page : 1;
      this.items = this.$route.query.items ? this.$route.query.items : 10;
      this.from_date = this.$route.query.from_date;
      this.to_date = this.$route.query.to_date;
      this.getData();
    },
    name() {
      if (this.name.length == 0 || this.name.length > 2) {
        this.updateQuery("page", 1);
        this.getData();
      }
    },
  },
  methods: {
    getData() {
      this.is_loading = true;
      if (this.cancelToken) {
        this.is_loading = true;
        this.cancelToken.cancel();
      }
      this.cancelToken = axios.CancelToken.source();
      let params = {
        from_date: this.$store.getters["data/fromDate"],
        to_date: this.$store.getters["data/toDate"],
        page: this.page,
        items: this.items,
        ...(this.name ? { name: this.name } : {}),
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "forum/log?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancelToken.token })
        .then((res) => {
          if (res.status == 200 && res.data.log_scraper.length > 0) {
            this.table_data.items = res.data.log_scraper;
            this.table_data.page_info = res.data.page_info[0];
          } else {
            this.table_data.items = [];
            this.table_data.page_info = {
              limit: 10,
              page: 1,
              total: 0,
              _id: null,
            };
            this.is_loading = false;
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          if (this.table_data.items) {
            this.is_loading = false;
          }
        });
    },
    updateQuery(key, value) {
      let queries = JSON.parse(JSON.stringify(this.$route.query));
      queries[key] = value;
      if ($.param(this.$route.query) != $.param(queries)) {
        this.$router.replace({ query: queries });
      }
    },
    viewDetail(id) {
      let item = this.table_data.items.find((el) => el._id == id);
      if (item.status == "Error" || item.status == "error") {
        this.$bvModal.show("modal-detail-forum");
        this.$refs.detail_forum.getData(id);
      }
    },
  },
};
</script>